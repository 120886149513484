import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/peterkrajcovic/Documents/Web/GatsbyProject/globaltel_V5_actual/src/components/newsLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>Odborník pre informatizáciu štátu: Systém pre e-Kolky otvára nové možnosti</h1>
    <p>
    Od roku 2015 štát neprijíma platby za súdne a správne poplatky vo forme papierových kolkov
    a nahradil ich modernejšou verziou, e-Kolkami. Systém bol spustený do ostrej prevádzky rok
    predtým a odvtedy funguje nepretržite. O budovaní systému a jeho ďalších možnostiach sme sa
    porozprávali s Rolandom Takácsom, biznis konzultantom, ktorý spolupracoval na tvorbe systémov
    pre e-Kolky.
    </p>
    <p>
    <strong>e-Kolok znie moderne a internetovo, čo to vlastne znamená?</strong>
    <br />
    Zjednodušenie povedané, e-Kolok je náhrada papierovej ceniny unikátnym kódom. Čiže aj keď je
    e-Kolok väčšinou vytlačený na papier, jeho jediným ochranným prvkom je kód, ktorý ho definuje.
    Po jeho použití sa zaznamená použitie a e-Kolok sa viac nedá použiť. Poznáme to aj z iných
    oblastí, napríklad v podobe tiketu stávkovej kancelárie, elektronickej letenky a podobne.
    </p>
    <p>
    <strong>Papierové kolky boli zaužívané a fungovali, načo bola potrebná zmena?</strong><br />
    Áno, boli sme na papierové kolkové známky zvyknutí, ale známky bolo treba distribuovať, predávať
    v trafikách a výdajných automatoch, občan ich musel kupovať v počtoch tak, aby vyskladal hodnotu
    poplatku z nominálnych hodnôt kolkov. Niekedy boli problémy s distribúciou, štát nevedel
    jednoduchým spôsobom zistiť, koľko kolkov a v akej nominálnej hodnote je v distribúcii, koľko je
    nakúpených a nepoužitých a koľko už bolo uplatnených. Preto nevedel efektívne riadiť ich životný
    cyklus. Spomeňme si na nedávne doby, keď boli kolky nedostatkový tovar, niektoré nominálne
    hodnoty úplne chýbali a za poplatok na katastri sa muselo lepiť aj zo desať kolkov.<br />
    e-Kolkov je teraz výrazne menej, keďže sa väčšinou vydávajú na presnú hodnotu poplatku. Zároveň
    je v centrálnych informačných systémoch zabezpečená rýchla evidencia predaja a spotreby.<br />
    No a nezabúdajme na elektronické platby za podania z pohodlia domova alebo práce prostredníctvom
    ústredného portálu. Prilepiť papierové kolky na obrazovku počítača veľký zmysel nedáva
    a spravovať dva odlišné mechanizmy pre platby za tie isté úkony je zbytočne ekonomicky náročné.
    e-Kolky to vyriešili.
    </p>
    <p>
    <strong>e-Kolky sa dajú kúpiť rôznymi spôsobmi, ide to aj cez mobil?</strong>
    <br />
    Mobilná aplikácia pre nákup e-Kolkov cez mobil existuje. Je to prvá verzia, ktorá pomáha najmä
    tým ľuďom, ktorí platia správne poplatky často, ako sú napríklad realitní agenti. Medzi bežnými
    občanmi ešte nie je veľmi rozšírená a mnohí o nej ani nevedia. Určite sa dá vylepšovať, ale to
    nebude hlavný problém, prečo nie je v povedomí občanov. Ten spočíva skôr v nedostatočnej
    propagácii. <br />
    Zároveň sa ešte občas stáva, že štátny úradník má potrebu e-Kolok k podaniu pripnúť a samotné
    nasnímanie kódu e-Kolku napríklad z displeja mobilu mu nepostačuje. Moderné prístupy vyžaduje aj
    revolúciu v myslení zástupcov štátnej správy.
    </p>
    <p>
    <strong>Systém je stabilný? Čo ak sa zrazu zvýši počet platieb e-Kolkami?</strong>
    <br />
    Systém je už dnes pripravený na zvýšenú záťaž. Nepredpokladá záťaž rovnomernú, ale nárazovú, kde
    maximum môže predstavovať mnohonásobok bežného používania centrálnych systémov. Je pružný,
    pokojne by zvládol aj pridanie ďalších typov poplatkov pre štátnu správu. Napríklad pridanie
    platieb za rôzne druhy pokút sa núka ako logické, nie príliš finančne náročné, rozšírenie
    využívania už vybudovaného riešenia.</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      